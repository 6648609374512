import { render, staticRenderFns } from "./CardDesign.vue?vue&type=template&id=538d3197&scoped=true&"
import script from "./CardDesign.vue?vue&type=script&lang=js&"
export * from "./CardDesign.vue?vue&type=script&lang=js&"
import style0 from "./CardDesign.vue?vue&type=style&index=0&id=538d3197&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "538d3197",
  null
  
)

export default component.exports